import React from "react"
import { allBankersData } from "@utils/mb.js"
export let bankerData
export const wrapPageElement = ({ element, props }) => {
  bankerData = allBankersData.find((mb) => {
    return (
      props.params.mb === mb.slug ||
      props.pageContext.slug === mb.slug ||
      props.path?.split("/")[1] === mb.slug
    )
  })
  const allProps = { ...props, bankerData }
  return <React.Fragment {...allProps}>{element}</React.Fragment>
}
